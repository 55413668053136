body {
  color: rgb(0, 0, 0);
  background-color: #CD84D3;
  font-family: 'Poppins', sans-serif;
}

h1 {
  font-size: 50px;
  color: black;
  text-align: center;
  padding: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
}

h3 {  
  font-size: 50px;
  color: #CD84D3;
  text-transform: uppercase;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  margin: 0px 0 0;
  padding-top: 0px; 
  padding-bottom: 0px;
}

div.container {
  text-align: center;
}

figure {
width: 400px;
}

ul.myUL {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  font-size: 30px;
}

.button {
  background-color: #8D56B9;
  padding: 100;
  border-radius: 4px;
  font-size: 30px;
  padding: 14px 28px;
  font-family: 'Times New Roman', Times, serif;
  color: rgba(0, 0, 0, 0.961);
  position: relative;
  position: absolute;
  top: 59%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.container {
  padding-bottom: 25px;
}

.button > a {
  color: black;
  text-decoration: none;
  float: left;  
}

#five {
  width: 50%;
  float: left;
  font-size: x-large;
}

#ten {
  width: 50%;
  float: right;
  font-size: x-large;
}

p {
  font-size: 25px;
  color: black;
  text-transform: uppercase;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  margin: 0px 0 0;
  padding-top: 50%; 
  padding-bottom: 0px;
  
}

/* container for cards */

.card-holder {
  background-color: #001F3F;
  /*left: 50%;*/
  position: relative;
  display:grid;
  grid-template-columns: (4);
  grid-auto-flow: column;
  justify-content: center;
  column-gap:20px;
}

/* styling cards */

.flip-card {
background-color: transparent;
width: 200px;
height: 300px;
margin:8%;
}

/* position and transform */

.flip-card-inner {
position: relative;
width: 100%;
height: 100%;
transition: transform 0.3s;
transform-style:preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
outline: solid;
outline-style: groove;
outline-width: 2px;
position: absolute;
width: 100%;
height: 100%;
-webkit-backface-visibility: hidden; /* Safari */
backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
background-color: #bbb;
background-image: url();
color: black;
}

/* Style the back side */
.flip-card-back {
transform: rotateY(180deg);
}

.flip-card-back-image {
  position:inherit;
  position:absolute;
  width:100%;
  height:100%;
  background-image:url();
  filter:blur(4px);
  -webkit-transform:scaleX(-1);
  -moz-transform:scaleX(-1);
  -ms-transform:scaleX(-1);
  -o-transform:scaleX(-1);
  transform:scaleX(-1);
}

.flip-card-back-button {
  width:100%;
  height:100%;
  position: absolute;
}

.card-button {
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  font-weight:900;
  font-size: 35px;
  -webkit-text-stroke-width:2px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 1);
}

.quit-button {
  position:relative;
  margin:25px;
  height:100%;
}

.quit-button > button {
  position:relative;
  color: #fff;
  border: 3px solid black;
  font-family:'Poppins', sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  padding: 10px;
  outline: none;
  border-radius: 4px;
  margin:0;
  top: 50%;
  left: 48%;
  transition: all .25s ease-in-out;
  transform: translateY(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.quit-button > button:hover {
  color: #001F3F;
  background-color: rgb(500, 26, 26);
}

.quit-button > button > a {
  position:inherit;
  height:inherit;
  width:inherit;
  text-align: center;
  color: black;
  text-decoration: none;
}

.web-player {
  position: relative;
  margin:1%;
  height:200px;
}

.controls {
  display:flex;
  position:relative;
  justify-content:center;
}

.play-pause-button {
  font-family: 'Poppins', sans-serif;
  width: 10rem;
}