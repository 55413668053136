body {
  color: #ffffff;
  background-color: #121212;
  font-family: "Circular Std", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h1,
h2 {
  font-size: 2.5em;
  color: #1db954;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

h1 {
  text-align: center;
}

h2 {
  text-align: left;
  align-items: left;
}

h3 {
  font-size: 2em;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: normal;
  line-height: 1.2;
  text-align: center;
  margin: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.head-banner {
  height: 10%;
}

.img-button,
.button,
.link-as-button,
.play-pause-button,
.login-button {
  cursor: pointer;
  text-decoration: none;
  border-radius: 50px;
  transition: background-color 0.3s ease;
  margin: 5px;
  border: none;
  background-color: #1db954;
  color: #ffffff;
  font-size: large;
}

.button,
.link-as-button,
.play-pause-button,
.login-button {
  padding: 10px 20px;
  font-size: 16px;
}

.img-button {
  background-color: transparent;
  padding: 0;
}

.button:hover,
.play-pause-button:hover,
.login-button:hover,
.quit-button:hover {
  background-color: #1ed760;
}

.container,
.button-container,
.controls,
.quit-button,
.spotify-player-container,
.flip-card-wrapper {
  text-align: center;
}
.row-background-dark,
.how-to-play,
.card-holder,
.flip-card-front,
.flip-card-wrapper {
  background-color: #282828;
}

.how-to-play {
  border-radius: 2%;
  gap: 30px;
  padding: 2rem; /* Consolidated padding */
  align-items: center;
  justify-content: center;
  background-color: #121212;
  width: 100%; /* Full width */
  margin: 0; /* Remove default margins if necessary */
  box-sizing: border-box; /* Include padding and border in the element's width */
  z-index: 101; /* Ensure it's above other elements if necessary */
}

.how-to-play h2 {
  margin-bottom: 1rem; /* Adds some space below the heading */
  text-align: left;
  padding-left: 2rem; /* Space for the bullet image */
  font-size: 2.5rem; /* Adjusts the font size */
  color: #1db954; /* Optional: Gives the heading a distinct color */
}

.myUL {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.myUL li {
  background: url("https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/Key-Signatures-flats.svg/1280px-Key-Signatures-flats.svg.png")
    no-repeat left center; /* Optional: Custom bullet image */
  background-blend-mode: color;
  padding-left: 2rem; /* Space for the bullet image */
  margin-bottom: 1rem; /* Space between list items */
  text-align: left; /* Aligns the text to the left */
  line-height: 1.6; /* Adjusts the spacing between lines */
  font-size: 2rem; /* Adjusts the font size */
}

/* If you want a custom bullet point */
.myUL li::before {
  content: "•"; /* Custom bullet */
  color: #1db954; /* Bullet color */
  display: inline-block; /* Allows it to sit next to the text */
  width: 1rem; /* Width of the bullet area */
  margin-left: -1rem; /* Positions the bullet correctly */
}

.login-container {
  display: flex;
  display: flex;
  flex-direction: column; /* Stacks children vertically */
  justify-content: center; /* Aligns items centrally in the container, change as needed */
  align-items: center; /* Centers items horizontally */
  position: fixed;
  left: 0;
  right: 0; /* Stretches across the full width */
  bottom: 170px; /* Adjust this value so it sits above the Spotify player */
  padding: 0 20px; /* Horizontal padding */
  z-index: 103; /* Above the Spotify player */
}

.login-container img {
  width: 200px;
  height: auto;
}

.login-button {
  width: 20%; /* Stretch to fill container width */
  margin-bottom: 10px; /* Adjust as needed for spacing */
  text-align: center; /* Center the text inside buttons */
}

.powered-by-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 150px;
  overflow: hidden;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 103%;
  background-color: #333;
  z-index: 102;
}

.img-logo,
.flip-card-front,
.flip-card-back {
  background-size: cover;
  background-position: center;
}

.img-logo {
  height: 50px;
}

.selected-answer-container-row{
  height: 60%;
}

.card-holder {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(200px, 1fr)
  ); /* Adjust minmax values for card width */
  grid-template-rows: repeat(2, minmax(200px, 1fr)); /* Two rows */
  gap: 30px;
  padding: 20px;
  align-items: center;
  justify-content: center;
  background-color: #121212;
}

.flip-card,
.correct-answer {
  background-color: transparent;
  align-items: center;
  justify-content: center;
  margin-left: 10vh;
  width: 15vh; /* Make flip cards adaptive */
  height: 25vh; /* Make flip cards adaptive */
  perspective: 1000px; /* Gives a depth effect on the flip */
}

.flip-card-inner {
  position: relative;
  width: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.no-hover-effect .flip-card-inner {
  transform: rotateY(0deg) !important;
}

.flip-card:hover .flip-card-inner {
  /* transform: rotateY(180deg); */
  transform: scale(1.1);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  border-radius: 3%;
  border-color: white;
  width: 100%;
  backface-visibility: hidden; /* Hides the back when flipping */
}

.flip-card-front {
  background-color: #282828;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
}

.flip-card-back {
  background-color: #282828;
  color: white;
  /* transform: rotateY(180deg); */
}

.answer-and-controls-section {
  display: flex;
  justify-content: space-between; /* Aligns items to the edges of the container */
  align-items: center; /* Centers items vertically */
  position: fixed;
  left: 0;
  right: 0; /* Stretches across the full width */
  bottom: 90px; /* Adjust this value so it sits above the Spotify player */
  padding: 0 20px; /* Horizontal padding */
  z-index: 103; /* Above the Spotify player */
}

.correct-answer-text {
  display: flex;
  align-items: center;
  /* No need for flex-grow; let it take its natural size */
}

.correct-answer-text p {
  color: white;
  margin: 0; /* No margin */
  padding: 10px 0; /* Vertical padding if needed */
}

.controls {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: -20px; /* Negative margin to counteract the container's padding */
}

.controls button {
  margin-left: 10px; /* Space between buttons */
  padding: 10px 20px; /* Consistent padding for buttons */
  font-size: 16px; /* Font size for buttons */
}

/* Spotify player and other components fixed positioning */
.spotify-player-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 80px;
  overflow: hidden;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #333;
  z-index: 102;
}

.cover-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 30%; /* Adjust based on how much you want to cover */
  background-color: #333; /* Same as the player's background for consistency */
  z-index: 101; /* Ensure it's above the Spotify player but under the content */
}

.cover-overlay-embedd {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 90%; /* Adjust based on how much you want to cover */
  background-color: #333; /* Same as the player's background for consistency */
  z-index: 101; /* Ensure it's above the Spotify player but under the content */
}

@media only screen and (max-width: 768px) {
  /* Styles for screens smaller than 600px in height */
  h1,
  h2 {
    font-size: 2em; /* Decrease font size for headings */
    padding-top: 0.5rem; /* Reduce padding */
    padding-bottom: 0.5rem; /* Reduce padding */
  }

  .link-as-button,
  .play-pause-button,
  .correct-answer-text,
  .login-button {
    padding: 8px 16px; /* Reduce padding for buttons */
    font-size: 10px; /* Decrease font size for buttons */
    height: 40px;

  }

  .login-container button {
    display: flex;
    align-items: center;
    justify-content: center;
    word-wrap: false;
    font-size: 12px;
    width: 90%;
  }

  .login-container img {
    width: 100px;
    margin-left: 20px;
  }

  .head-banner {
    height: 70px;
  }

  .selected-answer-container-row{
    top: 120px;
    left: 0;
    height: 60%;
    width: 100%;
    z-index: 105;
  }

  /* Adjust card sizes and layout */
  .flip-card,
  .flip-card-front,
  .flip-card-back,
  .card-button {
    max-width: 125px; /* Maximum width to prevent too large cards on slightly bigger screens */
    max-height: 200px; /* Maximum height, keeping a 3:2 aspect ratio as an example */
    margin-left: 20px;

  }

  /* Adjust the card holder to better fit the adjusted card sizes */
  .card-holder {
    grid-template-columns: repeat(
      auto-fill,
      minmax(150px, 2fr)
    ); 
    gap: 10px; 
    padding: 40px; 
    align-items: center;
    justify-content: center;
  }

  .answer-and-controls-section{
    height: 20px;
    z-index: 106;
  }

  .correct-answer-text{
    z-index: 103;
  }


  .correct-answer-text p {
    font-size: 1px;
    visibility: hidden;
  }

  .how-to-play {
    padding: 1rem; /* Reduce padding */
  }

  .how-to-play h2 {
    font-size: 2rem; /* Decrease font size */
    padding-left: 1rem; /* Reduce padding */
  }

  .myUL li {
    padding-left: 1rem; /* Reduce padding */
    font-size: 1.6rem; /* Decrease font size */
  }
}

@media only screen and (max-height: 900px) and (min-width: 768px ) {
  /* Styles for screens smaller than 800px in height */
  h1,
  h2 {
    font-size: 2.67em; /* Increase font size for headings */
    padding-top: 0.67rem; /* Adjust padding */
    padding-bottom: 0.67rem; /* Adjust padding */
  }

  .link-as-button,
  .play-pause-button,
  .correct-answer-text,
  .login-button {
    padding: 12px 20px; /* Adjust padding for buttons */
    font-size: 14px; /* Adjust font size for buttons */
  }

  .login-container button {
    font-size: 16px; /* Adjust font size */
    width: 80%; /* Adjust button width */
  }

  .login-container img {
    width: 120px; /* Adjust image width */
    margin-left: 30px; /* Adjust margin */
  }

  .head-banner {
    height: 90px; /* Adjust banner height */
  }

  .selected-answer-container-row {
    top: 160px; /* Adjust position */
    height: 50%; /* Adjust height */
    width: 100%;
  }

  /* Adjust card sizes and layout */
  .flip-card,
  .flip-card-front,
  .flip-card-back,
  .card-button {
    max-width: 150px; /* Adjust maximum width */
    max-height: 240px; /* Adjust maximum height */
    margin-left: 50px; /* Adjust margin */
  }

  /* Adjust the card holder */
  .card-holder {
    grid-template-columns: repeat(auto-fill, minmax(400px, 2fr)); /* Adjust column size */
    gap: 15px; /* Adjust gap */
    padding: 15px; /* Adjust padding */
    justify-content: center; /* Adjust alignment */
  }

  .answer-and-controls-section {
    height: 30px; /* Adjust height */
  }

  .correct-answer-text {
    z-index: 103;
  }

  .how-to-play {
    padding: 1.5rem; /* Adjust padding */
  }

  .how-to-play h2 {
    font-size: 2.5rem; /* Adjust font size */
    padding-left: 1.5rem; /* Adjust padding */
  }

  .myUL li {
    padding-left: 1.5rem; /* Adjust padding */
    font-size: 1.8rem; /* Adjust font size */
  }
}


@media only screen and (max-height: 700px) {
  /* Styles for screens smaller than 600px in height */
  h1,
  h2 {
    font-size: 2em; /* Decrease font size for headings */
    padding-top: 0.5rem; /* Reduce padding */
    padding-bottom: 0.5rem; /* Reduce padding */
  }


  .link-as-button,
  .play-pause-button,
  .correct-answer-text,
  .login-button {
    padding: 8px 16px; /* Reduce padding for buttons */
    font-size: 10px; /* Decrease font size for buttons */

  }

  .login-container button {
    display: flex;
    align-items: center;
    justify-content: center;
    word-wrap: false;
    font-size: 12px;
    width: 90%;
  }

  .login-container img {
    width: 100px;
    margin-left: 20px;
  }

  .head-banner {
    height: 70px;
  }

  .selected-answer-container-row{
    top: 120px;
    left: 0;
    height: 60%;
    width: 100%;
    z-index: 105;
  }

  /* Adjust card sizes and layout */
  .flip-card,
  .flip-card-front,
  .flip-card-back,
  .card-button {
    max-width: 125px; /* Maximum width to prevent too large cards on slightly bigger screens */
    max-height: 200px; /* Maximum height, keeping a 3:2 aspect ratio as an example */
    margin-left: 20px;
  }

  /* Adjust the card holder to better fit the adjusted card sizes */
  .card-holder {
    grid-template-columns: repeat(
      auto-fill,
      minmax(150px, 2fr)
    ); 
    gap: 10px; 
    padding: 10px; 
    justify-content: right;
  }

  .answer-and-controls-section{
    height: 20px;
    z-index: 106;
  }

  .correct-answer-text{
    z-index: 103;
  }


  .correct-answer-text p {
    font-size: 1px;
    visibility: hidden;
  }

  .how-to-play {
    padding: 1rem; /* Reduce padding */
  }

  .how-to-play h2 {
    font-size: 2rem; /* Decrease font size */
    padding-left: 1rem; /* Reduce padding */
  }

  .myUL li {
    padding-left: 1rem; /* Reduce padding */
    font-size: 1.6rem; /* Decrease font size */
  }
}